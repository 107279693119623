import React, { useEffect, useState } from 'react';
import './Components.css';
import { withRouter } from 'react-router-dom';
import {
    Drawer, Divider,
    ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { logoutAsync } from './Helpers/Authenticator';
import LogoTransperent from '../Images/logo_transparent.png';
import { IconUser, IconChartHistogram, IconMap2, IconCameraPin, IconSquareRoundedPlus, IconReportMoney, IconLogout } from '@tabler/icons-react';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        display: "flex"
    }
    ,
    docked: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
        borderColor: 'rgb(229, 231,235)'
    },
    mainDrawerItems: {
        flexGrow: 1
    }
}));

const PAGES = Object.freeze({
    profile: {
        id: 0,
        route: '/profile'
    },
    analytics: {
        id: 1,
        route: '/analytics'
    },
    experiences: {
        id: 3,
        route: '/experiences'
    },
    addExperience: {
        id: 4,
        route: '/add-experience'
    }
});


function Sidebar(props) {
    const [selectedPage, setSelectedPage] = useState(-1);
    const classes = useStyles();

    useEffect(function () {
        let pathname = window.location.pathname;
        
        if (pathname === PAGES.profile.route) {
            setSelectedPage(PAGES.profile.id);
        } else if (pathname === PAGES.analytics.route) {
            setSelectedPage(PAGES.analytics.id);
        } else if (pathname === PAGES.experiences.route) {
            setSelectedPage(PAGES.experiences.id)
        } else if (pathname === PAGES.addExperience.route) {
            setSelectedPage(PAGES.addExperience.id)
        }
    }, [])

    function navigateTo(page) {
        setSelectedPage(page.id)
        props.history.push(page.route);
    }

    async function logout() {
        await logoutAsync();
        window.location.reload();
    }

    return (
        <>
            <Drawer
                variant="permanent"
                anchor="left"
                className={`${classes.drawer} sidebar`}
                classes={{ paper: classes.drawerPaper, docked: classes.docked }}
            >
                <div className="div-logo" style={{ marginBottom: 40 }}>
                    <img alt="logo" src={LogoTransperent} />
                </div>
                <div className="sb-header">
                    <ListItem button selected={selectedPage === PAGES.profile.id} onClick={e => navigateTo(PAGES.profile)}>
                        <ListItemIcon>
                            <IconUser stroke={selectedPage === PAGES.profile.id ? 2.4 : 1.5}
                                width='30'
                                color={selectedPage === PAGES.profile.id ? '#000' : 'rgba(0,0,0,0.7)'}
                                className='user-icon' />
                        </ListItemIcon>
                        <ListItemText primary="Profile" style={{ letterSpacing: '0.025em' }} />
                    </ListItem>
                </div>
                <Divider />
                <div className={`sb-body ${classes.mainDrawerItems}`}>
                    <ListItem button selected={selectedPage === PAGES.analytics.id} onClick={e => navigateTo(PAGES.analytics)}>
                        <ListItemIcon>
                            <IconChartHistogram stroke={selectedPage === PAGES.analytics.id ? 2.4 : 1.5}
                                width='30'
                                color={selectedPage === PAGES.analytics.id ? '#000' : 'rgba(0,0,0,0.7)'}
                                className='analytics-icon' />
                        </ListItemIcon>
                        <ListItemText primary="Analytics" />
                    </ListItem>
                    <ListItem button selected={selectedPage === PAGES.experiences.id} onClick={e => navigateTo(PAGES.experiences)}>
                        <ListItemIcon>
                            <IconCameraPin stroke={selectedPage === PAGES.experiences.id ? 2.4 : 1.5}
                                width='30'
                                color={selectedPage === PAGES.experiences.id ? '#000' : 'rgba(0,0,0,0.7)'}
                                className='places-icon' />
                        </ListItemIcon>
                        <ListItemText primary="Experiences" />
                    </ListItem>
                    <ListItem button selected={selectedPage === PAGES.addExperience.id} onClick={e => navigateTo(PAGES.addExperience)}>
                        <ListItemIcon>
                            <IconSquareRoundedPlus stroke={selectedPage === PAGES.addExperience.id ? 2.4 : 1.5}
                                width='30'
                                color={selectedPage === PAGES.addExperience.id ? '#000' : 'rgba(0,0,0,0.7)'}
                                className='new-place-icon' />
                        </ListItemIcon>
                        <ListItemText primary="Add experience" />
                    </ListItem>
                </div>
                <div className="sb-footer">
                    <ListItem button onClick={e => logout()}>
                        <ListItemIcon>
                            <IconLogout stroke={2}
                                width='22'
                                color='rgba(0,0,0,0.7)'
                                className='log-out-icon' />
                        </ListItemIcon>
                        <ListItemText primary="Log out" />
                    </ListItem>
                </div>
            </Drawer>
        </>
    );
}

export default withRouter(Sidebar);