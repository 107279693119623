import React, { useState, useEffect } from 'react';
import './Components.css';
import { withRouter } from 'react-router-dom';
import LogoTransperent from '../Images/logo_transparent.png';
import { IconChartHistogram } from '@tabler/icons-react';

function Header(props) {
    const [selectedPage, setSelectedPage] = useState(-1);

    useEffect(function () {
        let pathname = window.location.pathname;

        if (pathname === '/analytics') {
            setSelectedPage('analytics');
        }
    }, [])

    function navigateTo() {
        setSelectedPage('analytics')
        props.history.push('/analytics');
    }

    return (
        <div className='header-container'>
            <div className="hdr-logo">
                <img alt="logo" src={LogoTransperent} />
            </div>
            <button className='hdr-profile-btn' onClick={navigateTo}>
                <IconChartHistogram stroke={selectedPage === 'analytics' ? 2.4 : 1.5}
                    width={28}
                    height={28}
                    color={selectedPage === 'analytics' ? '#000' : 'rgba(0,0,0,0.7)'}
                    className='user-icon' />
            </button>
        </div>
    );
}

export default withRouter(Header);