import { gql } from '@apollo/client';
import client from './../../ApolloClient';

const GET_NODE_NAMES_LIST = gql`
  query ($limit: Int!, $search_string: String!){
        getNodeNamesList(limit: $limit, search_string: $search_string){
            id
            value
        }
    }
`;

const GET_NODE_LOCATION = gql`
  query ($id: Int!){
        getNodeLocation(id: $id){
            city
            province
            country
            lat
            lng
        }
    }
`;

const GET_NODE_TYPES = gql`
  query ($id: Int!){
        getNodeTypes(id: $id){
            id
            name
            img
        }
    }
`;

const GET_NODE_ALL_TYPES = gql`
  query {
        getNodeAllTypes{
            id
            name
            img
        }
    }
`;

const SIGNED_IN_CREATORS_GET_SHORT_INFO = gql`
  query {
        signedInCreatorsGetInfo{
            id
            first_name
            instagram
            joined_at
        }
    }
`;

const CREATOR_GET_ANALYTICS = gql`
  query {
        creatorsGetAnalytics{
            analytics_key
            created_at
            total_count
        }
    }
`;

const CREATOR_GET_INFO = gql`
    query($ig: String!) {
        creatorsGetInfo(ig: $ig){
            account_type
            first_name
            last_name
            instagram
            member_count
            img
            city
            country
            bio
            creates_itinerary
            survey_link
            joined_at
            itinerary_arvel_fee
            nodes{
                id
                lat
                lng
                country
                statistics{
                    cuisine
                    elevation_gain_meter
                    hike_length_meter
                }
            }
        }
    }
`;

const CREATOR_GET_PLACES = gql`
    query($input: CreatorsGetNodesInput!) {
        creatorsGetNodes(input: $input){
            pagination{
                page_size
                after
                before
            }
            nodes{
                id
                name
                city
                province
                country
                area
                summary
                created_at
                media{
                    summer
                }
                analytics{
                    key
                    value
                }
            }
        }
    }
`;

const CREATOR_GET_ITINERARY_ORDERS = gql`
  query {
        creatorsGetItineraryOrders{
            id
            created_at
            completed_at
            description
            destination
            price
            creator_paid
        }
    }
`;

const CREATOR_GET_ITINERARY_ORDER_DETAILS = gql`
  query ($id: Int!){
        creatorsGetItineraryOrderDetails(id: $id){
            id
            description
            destination
            created_at
            completed_at
            price
            user_email
            survey_response{
                order_index
                question
                response
            }
        }
    }
`;

const CREATORS_GET_ITINERARY_PRICE = gql`
  query {
        creatorsGetItineraryPrice{
            product_id
            description
            price
            last_updated_at
        }
    }
`;

export async function GetNodeAllTypes() {
    try {
        var result = await client.query({
            query: GET_NODE_ALL_TYPES
        });

        return result?.data?.getNodeAllTypes
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function GetNodeNamesList(limit, search_string) {
    try {
        var result = await client.query({
            query: GET_NODE_NAMES_LIST,
            variables: {
                limit: limit,
                search_string: search_string
            }
        });

        return result?.data?.getNodeNamesList
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function GetNodeLocation(id) {
    try {
        var result = await client.query({
            query: GET_NODE_LOCATION,
            variables: {
                id: id
            }
        });

        return result?.data?.getNodeLocation
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function GetNodeTypes(id) {
    try {
        var result = await client.query({
            query: GET_NODE_TYPES,
            variables: {
                id: id
            }
        });

        return result?.data?.getNodeTypes
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function SignedInCreatorsGetShortInfo() {
    try {
        var result = await client.query({
            query: SIGNED_IN_CREATORS_GET_SHORT_INFO
        });
        return result?.data?.signedInCreatorsGetInfo
    } catch (e) {
        console.log('Error: ', e);
        return null
    }
}

export async function GetCreatorsAnalytics() {
    try {
        var result = await client.query({
            query: CREATOR_GET_ANALYTICS,
        });
        return result?.data?.creatorsGetAnalytics
    } catch (e) {
        console.log('Error: ', e);
        return null
    }
}

export async function GetCreatorsInfo(ig) {
    try {
        var result = await client.query({
            query: CREATOR_GET_INFO,
            fetchPolicy: 'network-only',
            variables: {
                ig: ig
            }
        });

        return result?.data?.creatorsGetInfo
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function GetCreatorsPlaces(page_size, ig, pageToken) {
    try {
        var result = await client.query({
            query: CREATOR_GET_PLACES,
            fetchPolicy: 'network-only',
            variables: {
                input: {
                    ig: ig,
                    pagination: {
                        page_size: page_size,
                        after: pageToken
                    }
                }
            }
        });

        return result?.data?.creatorsGetNodes
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function GetCreatorsItineraryOrders() {
    try {
        var result = await client.query({
            query: CREATOR_GET_ITINERARY_ORDERS
        });

        return result?.data?.creatorsGetItineraryOrders
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function GetCreatorsItineraryOrderDetails(id) {
    try {
        var result = await client.query({
            query: CREATOR_GET_ITINERARY_ORDER_DETAILS,
            variables: {
                id: id
            }
        });

        return result?.data?.creatorsGetItineraryOrderDetails
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function GetCreatorsItineraryPricing() {
    try {
        var result = await client.query({
            query: CREATORS_GET_ITINERARY_PRICE
        });

        return result?.data?.creatorsGetItineraryPrice
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}