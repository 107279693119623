import React from "react";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import * as notFound from "../../Lottie/not-found.json";
import * as loading from "../../Lottie/loader-circle.json";
import * as doneLoading from "../../Lottie/done-loading.json";
import * as loadingWhiteCircle from "../../Lottie/loader-circle-white.json";
import * as doneCercleCheckMark from "../../Lottie/done-circle-checkmark.json";
import * as addNewPlaceLoading from "../../Lottie/add-new-place-loading.json";
import * as addNewPlaceLoadingError from "../../Lottie/add-new-place-loading-error.json";
import * as addNewPlaceLoadingSuccess from "../../Lottie/add-new-place-loading-success.json";
import * as signInMob from "../../Lottie/sign-in-mob.json";
import * as hereToHelp from '../../Lottie/we-are-here-to-help.json';

export default function DynamicIcon(props) {
    var iconList = {
        hereToHelp: {
            loop: true,
            autoplay: true,
            animationData: hereToHelp.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        signInMob: {
            loop: true,
            autoplay: true,
            animationData: signInMob.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        addNewPlaceLoadingSuccess: {
            loop: true,
            autoplay: true,
            animationData: addNewPlaceLoadingSuccess.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        addNewPlaceLoadingError: {
            loop: true,
            autoplay: true,
            animationData: addNewPlaceLoadingError.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        addNewPlaceLoading: {
            loop: true,
            autoplay: true,
            animationData: addNewPlaceLoading.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        notFound: {
            loop: true,
            autoplay: true,
            animationData: notFound.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        loading: {
            loop: true,
            autoplay: true,
            animationData: loading.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        loadingWhiteCircle: {
            loop: true,
            autoplay: true,
            animationData: loadingWhiteCircle.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        doneLoading: {
            loop: true,
            autoplay: true,
            animationData: doneLoading.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        doneCercleCheckMark: {
            loop: true,
            autoplay: true,
            animationData: doneCercleCheckMark.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        }
    };

    return (
        !props.type
            ? ''
            : <FadeIn>
                <div className="d-flex justify-content-center align-items-center">
                    <Lottie options={iconList[props.type]} height={parseInt(props.height)} width={parseInt(props.width)} />
                </div>
            </FadeIn>
    );
}