import { gql } from '@apollo/client';
import client from './../../ApolloClient';

const SEND_EMAIL_NOTIFICATION = gql`
    mutation($input: UpdateInput!) {
        sendUpdate(input: $input)
    }
`;

const CREATORS_ADD_NEW_PLACE = gql`
    mutation($input: AddNewPlace!) {
        creatorsAddNewPlace(input: $input)
    }
`;

const CREATORS_UPDATE_PLACE_SUMMARY = gql`
    mutation($input: UpdatePlaceSummary!) {
        creatorsUpdatePlaceSummary(input: $input)
    }
`;

const CREATORS_UPDATE_PLACE_MEDIA = gql`
    mutation($input: UpdatePlaceMedia!) {
        creatorsUpdatePlaceMedia(input: $input)
    }
`;

const CREATORS_UPDATE_ITINERARY_PRICE = gql`
    mutation($input: [UpdateProductPrice!]) {
        creatorsUpdateItineraryPrice(input: $input)
    }
`;

const CREATORS_UPDATE_INFO = gql`
    mutation($input: UpdateCreatorInfo!) {
        creatorsUpdateInfo(input: $input)
    }
`;

const CREATORS_UPDATE_PROFILE_IMAGE = gql`
    mutation($image: Upload!) {
        creatorsUpdateProfileImage(image: $image)
    }
`;

export async function SendEmailNotification(subject, userEmail, message) {
    try {
        var result = await client.mutate({
            mutation: SEND_EMAIL_NOTIFICATION,
            variables: {
                input: {
                    subject, userEmail, message
                }
            }
        });

        return result?.data?.sendUpdate
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function CreatorsAddNewPlace(placeObj) {
    try {
        var result = await client.mutate({
            mutation: CREATORS_ADD_NEW_PLACE,
            variables: {
                input: placeObj
            }
        });

        return result?.data?.creatorsAddNewPlace
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function UpdateCreatorsPlaceSummary(id, summary) {
    try {
        var result = await client.mutate({
            mutation: CREATORS_UPDATE_PLACE_SUMMARY,
            variables: {
                input: {
                    id,
                    summary
                }
            }
        });

        return result?.data?.creatorsUpdatePlaceSummary
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function UpdateCreatorsPlaceMedia(id, media) {
    try {
        var result = await client.mutate({
            mutation: CREATORS_UPDATE_PLACE_MEDIA,
            variables: {
                input: {
                    id,
                    media
                }
            }
        });

        return result?.data?.creatorsUpdatePlaceMedia
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function UpdateCreatorsItineraryPrice(updated_list) {
    try {
        var result = await client.mutate({
            mutation: CREATORS_UPDATE_ITINERARY_PRICE,
            variables: {
                input: updated_list
            }
        });

        return result?.data?.creatorsUpdateItineraryPrice
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function UpdateCreatorsInfo(updated_info) {
    try {
        var result = await client.mutate({
            mutation: CREATORS_UPDATE_INFO,
            variables: {
                input: updated_info
            }
        });

        return result?.data?.creatorsUpdateInfo
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function UpdateCreatorsProfileImage(image) {
    try {
        var result = await client.mutate({
            mutation: CREATORS_UPDATE_PROFILE_IMAGE,
            variables: {
                image: image
            }
        });

        return result?.data?.creatorsUpdateProfileImage
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}