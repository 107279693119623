import React, { useState, useEffect } from 'react';
import './Components.css';
import { withRouter } from 'react-router-dom';
import { IconSearch, IconUser, IconCameraPin, IconSquareRoundedPlus } from '@tabler/icons-react';

const PAGES = Object.freeze({
    analytics: {
        id: 1,
        route: '/analytics'
    },
    experiences: {
        id: 2,
        route: '/experiences'
    },
    addExperience: {
        id: 3,
        route: '/add-experience'
    },
    profile: {
        id: 4,
        route: '/profile'
    }
});

function NavigationTabMobile(props) {
    const [selectedPage, setSelectedPage] = useState(-1);

    useEffect(function () {
        let pathname = window.location.pathname;

        if (pathname === PAGES.analytics.route) {
            setSelectedPage(PAGES.analytics.id);
        } else if (pathname === PAGES.experiences.route) {
            setSelectedPage(PAGES.experiences.id)
        } else if (pathname === PAGES.addExperience.route) {
            setSelectedPage(PAGES.addExperience.id)
        } else if (pathname === PAGES.profile.route) {
            setSelectedPage(PAGES.profile.id)
        }
    }, [])

    function navigateTo(page) {
        setSelectedPage(page.id)
        props.history.push(page.route);
    }

    const handleExplore = () => {
        window.location.href = 'https://arvel.app/experiences/worldwide';
    }

    return (
        <div className='navigation-tab-container'>
            <button className='nt-btn' onClick={handleExplore}>
                <IconSearch stroke={1.5}
                    width={28}
                    height={28}
                    color={'rgba(0,0,0,0.7)'} />
                <p>explore</p>
            </button>
            <button className='nt-btn' onClick={e => navigateTo(PAGES.experiences)}>
                <IconCameraPin stroke={selectedPage === PAGES.experiences.id ? 2.4 : 1.5}
                    width={28}
                    height={28}
                    color={selectedPage === PAGES.experiences.id ? '#000' : 'rgba(0,0,0,0.7)'} />
                <p style={{ color: (selectedPage === PAGES.experiences.id ? '#000' : 'rgba(0,0,0,0.7)') }}>experiences</p>
            </button>
            <button className='nt-btn' onClick={e => navigateTo(PAGES.addExperience)}>
                <IconSquareRoundedPlus stroke={selectedPage === PAGES.addExperience.id ? 2.4 : 1.5}
                    width={28}
                    height={28}
                    color={selectedPage === PAGES.addExperience.id ? '#000' : 'rgba(0,0,0,0.7)'} />
                <p style={{ color: (selectedPage === PAGES.addExperience.id ? '#000' : 'rgba(0,0,0,0.7)') }}>add experience</p>
            </button>
            <button className='nt-btn' onClick={e => navigateTo(PAGES.profile)}>
                <IconUser stroke={selectedPage === PAGES.profile.id ? 2.4 : 1.5}
                    width={28}
                    height={28}
                    color={selectedPage === PAGES.profile.id ? '#000' : 'rgba(0,0,0,0.7)'} />
                <p style={{ color: (selectedPage === PAGES.profile.id ? '#000' : 'rgba(0,0,0,0.7)') }}>profile</p>
            </button>
        </div>
    );
}

export default withRouter(NavigationTabMobile);