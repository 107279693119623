import { GetAuthToken, SetAuthToken, RemoveAuthToken } from './LocalStorage';
import { gql } from '@apollo/client';
import client from './../../ApolloClient';

const GET_CODE = gql`
  query($email: String!) {
    portalSignInCode(email: $email){
        expiresInMs
    }
}
`;

const SIGN_IN = gql`
    mutation($input: SignInInput!) {
       portalSignIn(input: $input)
    }
`;

export async function IsSignedInAsync() {
    var auth_token = GetAuthToken();
    return auth_token;
}

export async function SignInAsync(email, code) {
    try {
        var result = await client.mutate({
            mutation: SIGN_IN,
            variables: {
                input: {
                    email, 
                    code
                }
            }
        });

        if(result?.data?.portalSignIn){
            let savedToken = await SetAuthToken(result?.data?.portalSignIn);
            return savedToken
        }
    } catch (e) {
        console.log('Error: ', e);
    }

    return null
}

export async function GetCodeAsync(email) {
    try {
        var result = await client.query({
            query: GET_CODE,
            variables: {
                email
            }
        });
        return result?.data?.portalSignInCode?.expiresInMs
    } catch (e) {
        console.log('Error: ', e);
    }
    return null
}

export async function logoutAsync() {
    RemoveAuthToken();
    window.location.href = "/signin";
}