import React, { useState, useEffect } from 'react'
import '../Profile.css';
import { SignedInCreatorsGetShortInfo, GetCreatorsInfo } from '../../../Components/Helpers/Queries';
import DynamicIcon from '../../../Components/Helpers/DynamicIcon';
import { IconLink } from '@tabler/icons-react';
import UpdateProfileImg from './UpdateProfileImg';

export default function Image() {
    const [isImgUpdating, setIsImgUpdating] = useState(false)
    const [creatorIG, setCreatorIG] = useState(null)
    const [profilePic, setProfilePic] = useState(null)

    useEffect(function () {
        async function load() {
            let resultCreatorShortInfo = await SignedInCreatorsGetShortInfo()
            let resultCreatorLongInfo = await GetCreatorsInfo(resultCreatorShortInfo?.instagram)

            setCreatorIG(resultCreatorShortInfo?.instagram)
            setProfilePic(resultCreatorLongInfo?.img)
        };

        load();
    }, []);

    const handleViewArvelprofileClick = () => window.open('https://arvel.app/creators/' + creatorIG)

    const handleImageUpdateComplete = (URL) => {
        setProfilePic(URL)
        setIsImgUpdating(false)
    }

    const _renderCover = () => {
        if (!isImgUpdating) return

        return <div className='pc-image-cover'>
            <DynamicIcon type='loadingWhiteCircle' width={50} height={50} />
        </div>
    }

    return (
        <div className='pc-image-column'>
            <div className='profile-img background-image' style={{ backgroundImage: `url('${profilePic}')` }}>
                {_renderCover()}
                <button className='pc-img-action-btn pc-iab-link' disabled={isImgUpdating} onClick={handleViewArvelprofileClick}>
                    <IconLink stroke={1.9} width='20' height='20' color='#fff' />
                </button>
                <UpdateProfileImg imageUpdateStart={() => setIsImgUpdating(true)} imageUpdateComplete={handleImageUpdateComplete} />
            </div>
        </div>
    );
}