import React, { useState, useEffect } from 'react';
import './Analytics.css';
import { Helmet } from 'react-helmet';
import { Card, Metric, AreaChart } from "@tremor/react";
import Fade from 'react-reveal/Fade';
import { GetCreatorsAnalytics, GetCreatorsInfo, GetCreatorsItineraryOrders, SignedInCreatorsGetShortInfo } from '../../Components/Helpers/Queries';
import moment from "moment";
import { getDistance } from 'geolib';
import { ArrayOnlyUnique } from '../../Components/HelperFunctions';
import { SectionHeaderPopUpDescription } from '../../Components/HelperFunctions';
import Header from '../../Components/Header';
import NavigationTabMobile from '../../Components/NavigationTabMobile';

export default function Analytics() {
    const [chartData, setChartData] = useState(null);
    const [cardData, setCardData] = useState(null);
    const [windowWith, setWindowWidth] = useState(null);

    useEffect(() => {
        async function load() {
            let resultAnalytics = await GetCreatorsAnalytics()
            let resultCreatorShortInfo = await SignedInCreatorsGetShortInfo()
            let resultCreatorInfo = await GetCreatorsInfo(resultCreatorShortInfo?.instagram)
            let resultCreatorItineraries = await GetCreatorsItineraryOrders()

            formatChartData(resultAnalytics, resultCreatorShortInfo?.joined_at)
            formatCardsData(resultCreatorInfo?.nodes, resultCreatorItineraries)
            setWindowWidth(window.screen.width)
        };

        load();
    }, []);

    const formatCardsData = (nodes, itineraries) => {
        let statObj = {
            itineraries: itineraries ? itineraries?.filter(itinerary => itinerary.completed_at)?.length : 0,       // COUNT
            places: 0,                                                                                             // COUNT
            cuisine: 0,                                                                                            // COUNT
            hiked: 0,                                                                                              // IN METERS
            traveled: 0,                                                                                           // IN METERS
            elevationGained: 0,                                                                                    // IN METERS
            countries: ['']                                                                                        // COUNTRIES COUNT
        };

        statObj.places = nodes?.length

        for (let i = 0; i < nodes?.length; i++) {
            const node = nodes[i]

            if (node?.statistics?.cuisine) statObj.cuisine = statObj.cuisine + 1
            if (node?.statistics?.elevation_gain_meter) statObj.elevationGained = statObj.elevationGained + parseFloat(node?.statistics?.elevation_gain_meter)
            if (node?.statistics?.hike_length_meter) statObj.hiked = statObj.hiked + parseFloat(node?.statistics?.hike_length_meter)
            if (i !== 0) {
                statObj.traveled = statObj.traveled + getDistance(
                    { latitude: nodes[i - 1].lat, longitude: nodes[i - 1].lng },
                    { latitude: node.lat, longitude: node.lng }
                );
            }

            statObj.countries.push(node?.country)
        }

        statObj.countries = statObj.countries.splice(1, statObj.countries.length - 1).filter(ArrayOnlyUnique)
        setCardData(statObj)
    }

    const formatChartData = (data, creatorJoinedAt) => {
        const start_date = new Date(creatorJoinedAt)
        let end_date = new Date()
        let days_count = (end_date.getTime() - start_date.getTime()) / (1000 * 3600 * 24);

        let finalAnalytics = []

        for (let i = 1; i < days_count; i++) {
            finalAnalytics.push(
                {
                    date: moment(start_date.setDate(start_date.getDate() + 1)).format('MMM DD, YYYY'),
                    "Place views": 0,
                    "Profile views": 0
                }
            )
        }

        for (let j = 1; j < data?.length; j++) {
            for (let k = 1; k < finalAnalytics.length; k++) {
                if (data[j].created_at === finalAnalytics[k].date) {
                    const key = data[j].analytics_key
                    const value = data[j].total_count

                    if (key === 'Place view') finalAnalytics[k]["Place views"] = finalAnalytics[k]["Place views"] + value
                    else if (key === 'Creator profile view') finalAnalytics[k]["Profile views"] = finalAnalytics[k]["Profile views"] + value
                }
            }
        }

        setChartData(finalAnalytics)
    }

    return (
        <>
            <Helmet>
                <title>{'Analytics | Partner Portal'}</title>
            </Helmet>
            <div className="analytics-container">
                <Header />
                <NavigationTabMobile />
                <Fade>
                    <div className='ac-charts-container'>
                        <Card>
                            <div className='ac-charts-header-container'>
                                {SectionHeaderPopUpDescription('Your overview', 350, 'right',
                                    <div>Access real-time analytics for your profile and content on Arvel.
                                        <div className='ach-desc-header'>Place views</div>
                                        <div>This metric tracks the number of times your places have been viewed.</div>
                                        <div className='ach-desc-header'>Profile views</div>
                                        <div>This metric indicates how many times your Arvel profile has been visited.</div>
                                    </div>)}
                            </div>
                            <AreaChart
                                showAnimation={true}
                                showTooltip={true}
                                className="h-72 mt-4"
                                data={chartData}
                                index="date"
                                categories={["Place views", "Profile views"]}
                                colors={["amber", "violet"]}
                            />
                        </Card>
                    </div>
                    <div className='ac-cards-container'>
                        <Card className="max-w-xs mx-auto">
                            {SectionHeaderPopUpDescription('Places', 250, (windowWith > 600 ? 'top' : 'bottom'), 'This metric tracks the number of places you visited and shared on your Arvel profile.')}
                            <Metric style={{ marginTop: 5 }}>{cardData?.places}</Metric>
                        </Card>
                        <Card className="max-w-xs mx-auto">
                            {SectionHeaderPopUpDescription('Countries', 250, 'top', "This metric tracks the number of countries you've visited based on places you shared on Arvel profile.")}
                            <Metric style={{ marginTop: 5 }}>{cardData?.countries?.length}</Metric>
                        </Card>
                        <Card className="max-w-xs mx-auto">
                            {SectionHeaderPopUpDescription('Traveled', 250, 'top', 'This metric tracks the combined distance you traveled based on places you shared on Arvel profile.')}
                            <Metric style={{ marginTop: 5 }}>{cardData?.traveled > 0 ? (Math.round(cardData?.traveled / 1000 / 1000) + 'K km') : 0}</Metric>
                        </Card>
                        <Card className="max-w-xs mx-auto">
                            {SectionHeaderPopUpDescription('Cuisines', 250, 'top', 'This metric tracks the cuisines you have tasted based on places you shared on Arvel profile.')}
                            <Metric style={{ marginTop: 5 }}>{cardData?.cuisine}</Metric>
                        </Card>
                        <Card className="max-w-xs mx-auto">
                            {SectionHeaderPopUpDescription('Hiked', 250, 'top', 'This metric tracks the combined trails you have hiked based on places you shared on Arvel profile.')}
                            <Metric style={{ marginTop: 5 }}>{cardData?.hiked > 0 ? (Math.round(cardData?.hiked / 1000) + ' km') : 0}</Metric>
                        </Card>
                    </div>
                </Fade>
            </div>
        </>
    );
}